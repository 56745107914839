
import { defineComponent } from '@vue/runtime-core'
import { useRoute } from 'vue-router'
import { MetaBuildVersion } from './types'
import { onMounted, ref } from 'vue'

export default defineComponent({
  setup () {
    const route = useRoute()
    const menu = [
      { name: 'Проекты', route: { path: '/projects' }, childRoute: { name: 'project-id' }, icon: require('@/assets/image/projects-link.svg') },
      { name: 'Пользователи', route: { path: '/employee' }, icon: require('@/assets/image/users-link.svg') },
      { name: 'Зоны', route: { path: '/areas' }, icon: require('@/assets/image/areas-link.svg') },
      { name: 'Терминалы', route: { path: '/terminals' }, icon: require('@/assets/image/terminals-link.svg') },
      { name: 'Товары', route: { path: '/products' }, icon: require('@/assets/image/products-link.svg') },
      { name: 'Товары в зонах', route: { path: '/products-in-areas' }, icon: require('@/assets/image/products-zone-link.svg') },
      { name: 'Документы', route: { path: '/documentation' }, childRoute: { name: 'documentation-id' }, icon: require('@/assets/image/documentation-link.svg') },
      { name: 'Роли', route: { path: '/roles' }, icon: require('@/assets/image/roles-link.svg') },
      { name: 'Журнал', route: { path: '/journal' }, icon: require('@/assets/image/journal-link.svg') },
      { name: 'Отчёты', route: { path: '/reports' }, icon: require('@/assets/image/reports-link.svg') }
    ]

    const metaBuildVersion = new MetaBuildVersion()
    const buildVersion = ref('')

    onMounted(async () => {
      buildVersion.value = (await metaBuildVersion.getBuildVersion()).buildVersion
    })

    return () => (
      <aside class="aside">
        <div class="wrapper">
          <div class="aside-item logo">РММ</div>
          { menu.map(item => {
            return <router-link to={item.route.path} class={route.path === item.route.path || item.childRoute?.name === route.name ? 'aside-item active' : 'aside-item'}>
              <img src={item.icon}/>
              <span>{item.name}</span>
            </router-link>
          })}
        </div>
        <p class="version mb-2">{buildVersion.value}</p>
      </aside>
    )
  }
})
