import { Entity, EntityMeta } from 'apptimizm-ui'
import { host } from '@/const'
import axios from '@/axios'

interface IUserRole {
  value: string
  name: string
}

export class User extends Entity {
  id: string = ''
  phone: string = ''
  role: IUserRole|undefined
  firstName: string = ''
  middleName: string = ''
  lastName: string = ''

  getName () {
    return this.role?.name
  }
}

export class UserMeta extends EntityMeta<User> {
  profileEndpoint = host + '/api/v1/user/me'
  entity = User
  fields = {
    id: { type: String },
    phone: { type: String },
    role: { type: Object, default: () => {} },
    firstName: { type: String, backendKey: 'first_name' },
    middleName: { type: String, backendKey: 'middle_name' },
    lastName: { type: String, backendKey: 'last_name' }
  }

  async getProfile () {
    const response = (await axios.get(this.profileEndpoint)).data
    return this.load(response)
  }
}
