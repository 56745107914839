import { isAuthorized } from '@/App.vue'
import axios from '@/axios'
import { host } from '@/const'
import { mutations } from '@/store'
import { Entity, EntityMeta } from 'apptimizm-ui'

export class BuildVersion extends Entity {
  buildVersion = ''
}

export class MetaBuildVersion extends EntityMeta<BuildVersion> {
  endpoint = '/settings/build_version/'

  fields = {
    buildVersion: { type: String, backendKey: 'build_version' }
  }

  async getBuildVersion () {
    try {
      return this.load((await axios.get(`${host}/api/v1${this.endpoint}`)).data) as BuildVersion
    } catch (error) {
      mutations.pushNotification('Не удалось получить версию билда', true)
      return new BuildVersion()
    }
  }
}