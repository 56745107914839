import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'
import { stringifyQuery } from 'vue-router'
import { Area, AreaMeta } from '../areas/types'
import { Employee, EmployeeMeta } from '../employee/types'
import { Product, ProductMeta } from '../products/types'

interface IStatus {
  name: string
  value: 'not_ready' | 'ready'
}

class ChooseController extends Entity {
  task: string = ''
  username: string = ''
  result: number = 0
}

class ChooseControllerMeta extends EntityMeta<ChooseController> {
  entity: typeof Entity = ChooseController

  fields = {
    task: { type: String },
    username: { type: String },
    result: { type: Number }
  }
}

class ScannedProducts extends Entity {
  id: string = ''
  number: number = 0
  createdAt: string = ''
  amount: number = 0
  product: Product = new Product()
  scannedTime: string = ''
  displayedDm: string = ''
}

class ScannedProductsMeta extends EntityMeta<ScannedProducts> {
  endpoint: string = host + '/api/v1/document/'
  endpointPatch: string = host + '/api/v1/scanned_product/'

  fields = {
    id: { type: String },
    number: { type: Number },
    createdAt: { type: String, backendKey: 'created_at' },
    amount: { type: Number },
    product: { type: Object },
    scannedTime: { type: String, backendKey: 'scanned_time' },
    displayedDm: { type: String, backendKey: 'displayed_dm' }
  }

  load (data: any): ScannedProducts {
    const result = super.load(data)
    const productMeta = new ProductMeta()
    result.product = productMeta.load(result.product)

    if (data.displayed_dm === null) result.displayedDm = ''

    return result
  }
}

class DocumentEntity extends Entity {
  id: string = ''
  fakeId: string = ''
  createdAtt: string = ''
  status: IStatus = { name: '', value: 'not_ready' }
  zone: Area = new Area()
  employee: Employee | null = null
  counterScan: number = 0
  counterController: number = 0
  counterControllerDiscrepancy: boolean = false
  auditorScan: number = 0
  auditorDiscrepancy: boolean = false
  auditorController: number = 0
  auditorControllerDiscrepancy: boolean = false
  auditorExternal: number = 0
  auditorExternalDiscrepancy: boolean = false
  storage: number = 0
  startAuditDate: string = ''
  endAuditDate: string = ''
  tsdNumber: number = 0
  suspicious: boolean = false
  color: string = ''
  colorChanged: boolean = false
  chooseControllers: ChooseController[] = []
  // typeTable необходим для корректной работы с сокетами
  typeTable = 'document'
}

class DocumentMeta extends EntityMeta<DocumentEntity> {
  endpoint: string = host + '/api/v1/document/'
  endpointScannedProduct: string = host + '/api/v1/document_scanned_products/'

  fields = {
    id: { type: String },
    fakeId: { type: String, backendKey: 'fake_id' },
    createdAtt: { type: String, backendKey: 'created_att' },
    status: { type: Object },
    zone: { type: Object },
    employee: { type: Object },
    counterScan: { type: Number, backendKey: 'counter_scan' },
    counterController: { type: Number, backendKey: 'controller' },
    counterControllerDiscrepancy: { type: Boolean, backendKey: 'counter_controller_discrepancy' },
    auditorScan: { type: Number, backendKey: 'auditor' },
    auditorDiscrepancy: { type: Boolean, backendKey: 'auditor_discrepancy' },
    auditorController: { type: Number, backendKey: 'auditor_controller' },
    auditorControllerDiscrepancy: { type: Boolean, backendKey: 'auditor_controller_discrepancy' },
    auditorExternal: { type: Number, backendKey: 'auditor_external' },
    auditorExternalDiscrepancy: { type: Boolean, backendKey: 'auditor_external_discrepancy' },
    storage: { type: Number },
    startAuditDate: { type: String, backendKey: 'start_audit_date' },
    endAuditDate: { type: String, backendKey: 'end_audit_date' },
    tsdNumber: { type: Number, backendKey: 'tsd_number' },
    color: { type: String },
    suspicious: { type: Boolean },
    colorChanged: { type: Boolean, backendKey: 'color_changed' },
    chooseControllers: { type: ChooseControllerMeta, backendKey: 'choose_controllers', many: true }
  }

  load (data: any): DocumentEntity {
    const result = super.load(data)
    if (data.employee) result.employee = new EmployeeMeta().load(data.employee)
    result.zone = new AreaMeta().load(data.zone)

    if (data.counter_scan === 0) result.counterScan = 0
    if (data.controller === 0) result.counterController = 0
    if (data.counter_controller_discrepancy === false) result.counterControllerDiscrepancy = false
    if (data.auditor === 0) result.auditorScan = 0
    if (data.auditor_discrepancy === false) result.auditorDiscrepancy = false
    if (data.auditor_controller === 0) result.auditorController = 0
    if (data.auditor_controller_discrepancy === false) result.auditorControllerDiscrepancy = false
    if (data.auditor_external === 0) result.auditorExternal = 0
    if (data.auditor_external_discrepancy === false) result.auditorExternalDiscrepancy = false
    if (data.storage === 0) result.storage = 0
    if (data.color_changed === false) result.colorChanged = false
    result.typeTable = 'document'

    return result
  }

  getRolesName (values: string[]): string {
    if (!values) return ''
    const result: string[] = []
    values.forEach(item => {
      if (item === 'counter') result.push('Счетчик')
      if (item === 'auditor') result.push('Аудитор')
      if (item === 'storage') result.push('Сотрудник склада')
    })

    return result.join(', ')
  }
}

class DocumentBlockStatistic extends Entity {
  countSum: number = 0
  docsCount: number = 0
  countAver: number = 0
}

class DocumentBlockStatisticMeta extends EntityMeta<DocumentBlockStatistic> {
  endpoint: string = `${host}/api/v1/document/block_statistic/`

  fields = {
    countSum: { type: Number, backendKey: 'count_sum' },
    docsCount: { type: Number, backendKey: 'docs_count' },
    countAver: { type: Number, backendKey: 'count_aver' }
  }
}

export {
  DocumentEntity,
  DocumentMeta,
  IStatus,
  ScannedProducts,
  ScannedProductsMeta,
  DocumentBlockStatistic,
  DocumentBlockStatisticMeta
}
