import { store, mutations } from '@/store'
import { Transition } from 'vue'

export const Notification = (): JSX.Element => {
  return (
    <div class="notification-list">
      {store.notification.map((item, idx) => (
        <Transition
          appear
          enterToClass="notification-enter"
          enterActiveClass="notification-enter"
          leaveToClass="notification-out"
          leaveActiveClass="notification-out"
        >
          {!item.checked ? <div
            key={idx}
            class={{ 'notification-item': true, error: item.error }}
            onClick={() => mutations.deleteNotification(idx)}
          >
            {item.message}
            <img class="close" src={require('../../assets/image/close-icon.svg')} alt="close"/>
          </div> : null}
        </Transition>
      ))}
    </div>
  )
}
