import { EntityMeta } from 'apptimizm-ui'
import axios from './axios'
import { IPaginationResponse } from './core/types'
import { AxiosInstance } from 'axios'

export function addTrailingSlash (s: string): string {
  return s[s.length - 1] === '/' ? s : s + '/'
}

export function removeTrailingSlash (s: string): string {
  return s[s.length - 1] === '/' ? s.slice(0, s.length - 1) : s
}

export function declOfNum (number: number, titles: string[]): string {
  const cases = [2, 0, 1, 1, 1, 2]
  return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]]
}

export async function getAllList<T> (meta: EntityMeta<T>, params: { [key: string]: string } = {}) {
  try {
    const perPage = 50
    const responseData = (await axios.get(meta.endpoint, { params: { ...params, per_page: perPage } })).data as IPaginationResponse<T>
    const items: T[] = []
    items.push(...responseData.results)

    if (responseData.next) {
      items.push(...await getOtherItems(meta.endpoint, responseData.count, perPage))
    }

    return items.map((item) => (
      meta.load(item)
    )) as T[]
  } catch (error) {
    console.error('Не удалось получить список: ', error)
    return []
  }
}

async function getOtherItems (endpoint: string, count: number, perPage: number) {
  const promisess: Promise<AxiosInstance>[] = []
  const pagesCount = Math.ceil(count / perPage) - 1

  for (let index = 0; index < pagesCount; index++) {
    const params = { per_page: perPage, page: index + 2 }
    promisess.push(axios.get(endpoint, { params }))
  }
  const responses = await Promise.all(promisess).catch(() => {
    console.error(`Не удалось получить все объекты по роуту ${endpoint}`)
    return [] as AxiosInstance[]
  })
  const results = responses.map((response) => {
    return (response as any).data.results
  })
  return results.flat()
}
