import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'

interface IZone {
  id: string
  title: string
  storageName: string
}

class Product extends Entity {
  id: string = ''
  vendorCode: string = ''
  barcode: string = ''
  title: string = ''
  remainder: number = 0
  price: string = ''
  zone: IZone = { id: '', title: '', storageName: '' }
  name: string = ''
  dm: string = ''
}

class ProductMeta extends EntityMeta<Product> {
  endpoint: string = host + '/api/v1/product/'

  fields = {
    id: { type: String },
    vendorCode: { type: String, backendKey: 'vendor_code' },
    barcode: { type: String },
    title: { type: String },
    remainder: { type: String },
    price: { type: String },
    zone: { type: Object },
    name: { type: String, backendKey: 'title' },
    dm: { type: String }
  }

  load (data: any): Product {
    const result = super.load(data)
    if (!data.zone) result.zone = { id: '', title: '', storageName: '' }
    if (!data.remainder) result.remainder = 0
    if (data.title === null) result.title = ''
    if (data.vendor_code === null) result.vendorCode = ''
    if (data.dm === null) result.dm = ''

    return result
  }
}

export { Product, ProductMeta, IZone }