import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'
import { Terminal, TerminalMeta } from '../terminals/types'
import { mutations } from '@/store'
import axios from '@/axios'

interface ISelectValue { name: string, value: string }
const terminalMeta = new TerminalMeta()

class Employee extends Entity {
  id: string = ''
  serialNumber: number = 0
  username: string = ''
  roles: ISelectValue[] = []
  norm: number = 0
  counterScan: number = 0
  counterController: number = 0
  auditorScan: number = 0
  auditorController: number = 0
  auditorExternal: number = 0
  storage: number = 0
  terminal: Terminal | null = null
  isDeleted: boolean = false
  isAutoAssignment = false
}

class EmployeeMeta extends EntityMeta<Employee> {
  endpoint: string = host + '/api/v1/employee/'

  fields = {
    id: { type: String },
    serialNumber: { type: Number, backendKey: 'serial_number' },
    username: { type: String },
    roles: { type: Array },
    norm: { type: Number },
    counterScan: { type: Number, backendKey: 'counter_scan' },
    counterController: { type: Number, backendKey: 'controller' },
    auditorScan: { type: Number, backendKey: 'auditor' },
    auditorExternal: { type: Number, backendKey: 'auditor_external' },
    auditorController: { type: Number, backendKey: 'auditor_controller' },
    storage: { type: Number },
    terminal: { type: Object },
    isDeleted: { type: Boolean, backendKey: 'is_deleted' },
    isAutoAssignment: { type: Boolean, backendKey: 'is_auto_assignment' }
  }

  load (data: any): Employee {
    const result = super.load(data)
    result.auditorController = data.auditor_controller
    result.counterController = data.controller
    result.auditorScan = data.auditor
    result.auditorExternal = data.auditor_external
    result.counterScan = data.counter_scan
    result.storage = data.storage
    result.roles = [...data.roles]
    if (data.terminal) result.terminal = terminalMeta.load(data.terminal)
    result.isDeleted = data.is_deleted
    return result
  }

  getRoles (items: ISelectValue[]) {
    return items.map(item => item.name).join(', ')
  }

  async update (id: string, data: { [key: string]: unknown }) {
    try {
      await axios.patch(`${this.endpoint}${id}`, data)
    } catch (error) {
      mutations.pushNotification('Не удалось обновить информаю о сотруднике', true)
    }
  }
}

interface IProjectForm {
  id: string
  name: string
}

class FormFields extends Entity {
  project: IProjectForm = { id: '', name: '' }
  startSerialNumber: number = 0
  endSerialNumber: number = 0
  amount: number = 0
  roles: string[] = []

  reset () {
    this.startSerialNumber = 0
    this.endSerialNumber = 0
    this.amount = 0
    this.roles = []
  }
}

class FormFieldsMeta extends EntityMeta<FormFields> {
  endpointCreate: string = host + '/api/v1/employee/bulk_create/'
  endpointDelete: string = host + '/api/v1/employee/bulk_delete/'

  fields = {
    project: { type: Object },
    startSerialNumber: { type: Number, backendKey: 'start_serial_number' },
    endSerialNumber: { type: Number, backendKey: 'end_serial_number' },
    amount: { type: Number },
    roles: { type: Array }
  }

  dump (item: FormFields): { [code: string]: any } {
    const result = super.dump(item)
    result.project = item.project.id
    result.roles = [...item.roles]

    return result
  }
}

export { Employee, EmployeeMeta, FormFields, FormFieldsMeta }