
import { defineComponent } from '@vue/runtime-core'
import { store } from '../../store'
import { computed, onMounted } from 'vue'
import { UserMeta } from './types'
import { useRoute } from 'vue-router'

export default defineComponent({
  setup () {
    const meta = new UserMeta()
    const route = useRoute()

    const checkRouteName = computed(() => {
      if (String(route.name)?.includes('project')) return true
      return false
    })

    onMounted(async () => {
      store.user = await meta.getProfile()
    })

    return () => (
      <div class="user" style={checkRouteName.value ? 'flex-direction: row; gap: 12px' : 'flex-direction: column; align-items: end'}>
        {checkRouteName.value
          ? <>
            <img src={require('@/assets/image/avatar.png')}/>
            <p>{store.user?.lastName || ''} {store.user?.firstName || ''} {store.user?.middleName || ''}</p>
          </>
          : <>
            <p>{store.currentProject?.name || 'Проект не выбран'}</p>
            <span>{store.currentProject?.address || ''}</span>
          </>
        }
      </div>
    )
  }
})
