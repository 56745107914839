import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'
import { declOfNum } from '@/utils'
import { ISelectValue } from '../projects/types'
import { store } from '@/store'

interface IEmployee { id: string, username: string, [key: string]: string }
interface ICounterScan { id: '', description?: '', employee?: IEmployee }
interface IStatus { name: '', value: 'not_ready' | 'ready' }

const declination = ['пользователь', 'пользователя', 'пользователей']

class Area extends Entity {
  id: string = ''
  serialNumber: number = 0
  name: string = ''
  storageName: string = ''
  barcodeAmount: number = 0
  status: IStatus = { name: '', value: 'not_ready' }
  isAutoAssignment: boolean = false
  isEmpty: boolean = false

  counterScan: ICounterScan[] = []
  isCounterScanCount: boolean = false

  counterController: ICounterScan[] = []
  isCounterControllerCount: boolean = false

  auditorScan: ICounterScan[] = []
  isAuditorScanCount: boolean = false

  auditorController: ICounterScan[] = []
  isAuditorControllerCount: boolean = false

  auditorExternalController: ICounterScan[] = []
  isAuditorExternalControllerCount: boolean = false

  storage: Array<ICounterScan> = []
  isStorageCount: boolean = false
  code: string = ''
  // typeTable необходим для корректной работы с сокетами
  typeTable: string = 'zone'
}

class AreaMeta extends EntityMeta<Area> {
  endpoint: string = host + '/api/v1/zone'

  fields = {
    id: { type: String },
    serialNumber: { type: Number, backendKey: 'serial_number' },
    isEmpty: { type: Boolean, backendKey: 'is_empty' },
    name: { type: String, backendKey: 'title' },
    storageName: { type: String, backendKey: 'storage_name' },
    isCounterScanCount: { type: Boolean, backendKey: 'is_counter_scan_count' },
    barcodeAmount: { type: Number, backendKey: 'barcode_amount' },
    status: { type: Object },
    isCounterControllerCount: { type: Boolean, backendKey: 'is_controller_count' },
    isAutoAssignment: { type: Boolean, backendKey: 'is_auto_assignment' },
    counterScan: { type: Object, backendKey: 'counter_scan' },
    counterController: { type: Object, backendKey: 'controller' },
    auditorScan: { type: Object, backendKey: 'auditor' },
    isAuditorScanCount: { type: Boolean, backendKey: 'is_auditor_count' },
    isAuditorControllerCount: { type: Boolean, backendKey: 'is_auditor_controller_count' },
    auditorController: { type: Object, backendKey: 'auditor_controller' },
    auditorExternalController: { type: Object, backendKey: 'auditor_external_controller' },
    isAuditorExternalControllerCount: { type: Boolean, backendKey: 'is_auditor_external_controller_count' },
    storage: { type: Array },
    isStorageCount: { type: Boolean, backendKey: 'is_storage_count' },
    сode: { type: String }
  }

  load (data: any): Area {
    const result = super.load(data)
    result.barcodeAmount = data.barcode_amount
    result.storage = data.storage
    result.code = data.code
    if (result.code === null) result.code = ''
    result.typeTable = 'zone'

    return result
  }

  getUserCount (item: ICounterScan[]) {
    return `${item.length} ${declOfNum(item.length || 0, declination)}`
  }
}

interface IProjectForm {
  id: string
  name: string
}

class FormFields extends Entity {
  project: IProjectForm = { id: '', name: '' }
  projectId: string = ''
  startSerialNumber: number = 0
  endSerialNumber: number = 0
  amount: number = 0
  role: ISelectValue = { name: '', value: '' }
  storageName: string = ''
  employees: string[] = []

  reset () {
    this.startSerialNumber = 0
    this.endSerialNumber = 0
    this.amount = 0
    this.storageName = ''
    this.employees = []
    this.role = { name: '', value: '' }
  }
}

class FormFieldsMeta extends EntityMeta<FormFields> {
  endpointCreate: string = host + '/api/v1/zone/bulk_create/'
  endpointDelete: string = host + '/api/v1/zone/bulk_delete/'
  endpointUpdate: string = host + '/api/v1/zone/bulk_update/'
  endpointIssusing: string = host + '/api/v1/zone/batch_issuing_tasks/'

  fields = {
    projectId: { type: String, backendKey: 'project_id' },
    project: { type: Object },
    startSerialNumber: { type: Number, backendKey: 'start_serial_number' },
    endSerialNumber: { type: Number, backendKey: 'end_serial_number' },
    role: { type: Object },
    amount: { type: Number },
    employees: { type: Array },
    storageName: { type: String, backendKey: 'storage_name' }
  }

  dump (item: FormFields): { [code: string]: any } {
    const result = super.dump(item)
    result.project = store.currentProject.id
    result.employees = [...item.employees]
    result.role = item.role.value

    return result
  }
}

class ZoneBlockStatistic extends Entity {
  barcodesSum: number = 0
  zonesCount: number = 0
}

class ZoneBlockStatisticMeta extends EntityMeta<ZoneBlockStatistic> {
  endpoint: string = `${host}/api/v1/zone/block_statistic/`

  fields = {
    barcodesSum: { type: Number, backendKey: 'barcodes_sum' },
    zonesCount: { type: Number, backendKey: 'zones_count' }
  }
}

export { Area, AreaMeta, FormFields, FormFieldsMeta, IEmployee, ZoneBlockStatistic, ZoneBlockStatisticMeta }