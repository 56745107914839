
import Auth from './auth/Auth.vue'
import Sidebar from './core/sidebar/index.vue'
import User from './core/user/index.vue'
import { AutomaticErrorPopup } from 'apptimizm-ui'
import { defineComponent, onMounted, Ref } from '@vue/runtime-core'
import { ref, watch } from 'vue'
import { Notification } from './core/notifications/notifications'
import { TableWebSocket } from './core/web-socket'
import { mutations } from './store'

export const isAuthorized: Ref<string> = ref('')

export default defineComponent({
  setup () {
    const tableWebSocket = new TableWebSocket()

    onMounted(() => {
      if (localStorage.auth) isAuthorized.value = localStorage.auth

      window.addEventListener('401 Unauthorized', () => {
        isAuthorized.value = ''
        localStorage.clear()
      })
    })

    watch(tableWebSocket.messages, v => {
      mutations.recordMessagesWS(v)
    }, { deep: true })

    return () => (
      <div>
        { isAuthorized.value ? (
          <div class="page-layout">
            <Sidebar/>
            <div class="content">
              <User/>
              <router-view/>
              <Notification/>
            </div>
          </div>
        ) : <Auth onAuth={() => { isAuthorized.value = localStorage.auth }}/> }
        <AutomaticErrorPopup/>
      </div>
    )
  }
})
