import { host } from '@/const'
import { Entity, EntityMeta } from 'apptimizm-ui'

class Terminal extends Entity {
  id: string = ''
  number: number = 0
  ipAddress: string = ''
  dbLoading: boolean = false
  lastConnect: string = ''
}

class TerminalMeta extends EntityMeta<Terminal> {
  endpoint: string = host + '/api/v1/terminal/'

  fields = {
    id: { type: String },
    number: { type: Number },
    ipAddress: { type: String, backendKey: 'ip_address' },
    dbLoading: { type: Boolean, backendKey: 'db_loading' },
    lastConnect: { type: String, backendKey: 'last_connect' }
  }
}

export { Terminal, TerminalMeta }